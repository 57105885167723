import React from "react";

const Loading = () => {
  return (
    <div
      className="container-fluid bg-dark px-0 mx-0 text-center"
      style={{ height: "100vh", paddingTop: "40vh" }}>
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
