import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { getAllBlogPosts } from "../functions/BlogPostFunctions";
import { sahil_url } from "../config";
import Loading from "./Loading";

const Blog = () => {
  const [isLoading, setIsLoading] = useState("true");
  const [selectedBlogPost, setSelectedBlogPost] = useState({
    _id: "",
    title: "",
    image_url: "",
    date: "",
    author: "",
    tags: [],
    reading_time: "",
    content: [],
    post_of_the_month: false,
    reference_links: [
      {
        name: "",
        url: "",
      },
    ],
  });
  useEffect(() => {
    let selectedBlogPostTitleUrl = window.location.href.split("/");
    let selectedBlogPostTitle = selectedBlogPostTitleUrl[4].replaceAll(
      "_",
      " "
    );
    getAllBlogPosts().then((blPsts) => {
      for (let blogPost of blPsts) {
        if (selectedBlogPostTitle == blogPost.title) {
          blogPost.content = blogPost.content.split("^");
          setSelectedBlogPost(blogPost);
          setIsLoading(false);
          break;
        }
      }
    });
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <div className="container-fluid bg-dark px-0 mx-0">
      <Navbar />
      <div className="text-light container mt-5 ps-4 ps-lg-5 pe-5">
        <h1>{selectedBlogPost.title}</h1>
        <div className="row">
          <div className="col-lg-1 col-4">
            <img
              src={sahil_url}
              alt="author"
              width="75"
              className="mt-3 rounded-circle me-3"
            />
          </div>
          <div className="col-lg-5 mt-lg-2 mt-3">
            <p className="mb-1 pb-0">
              <i className="fas fa-user-pen me-1" /> {selectedBlogPost.author}
            </p>
            <p className="mb-2 pb-0">
              <i className="fas fa-calendar-days me-2" />
              {selectedBlogPost.date} &nbsp; • &nbsp; &nbsp;
              <i className="fas fa-clock me-2" />
              {selectedBlogPost.reading_time}
            </p>
            <div>
              <i className="fas fa-tags me-2" />
              {selectedBlogPost.tags.map((tag) => {
                return (
                  <div
                    key={tag}
                    className="text-dark d-inline bg-light me-2 px-2 py-1 rounded fw-bold"
                    style={{ fontSize: "12px" }}>
                    {tag}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <img
          src={selectedBlogPost.image_url}
          alt="blog-post"
          width="650"
          className="img-fluid mt-4"
        />
        <div className="row mt-5">
          <div>
            {selectedBlogPost.content.map((content, index, array) => {
              if (
                content.trim().includes(".--. . .-. ... .--. . -.-. - .. . …-")
              ) {
                if (array[index + 1].trim().startsWith("ST*-")) {
                  return (
                    <div key={content}>
                      <p
                        className="fw-bold pt-3"
                        style={{ fontSize: "20px", color: "#00bdfe" }}>
                        {content}
                      </p>
                    </div>
                  );
                } else {
                  return (
                    <div key={content}>
                      <p
                        className="fw-bold"
                        style={{ fontSize: "20px", color: "#00bdfe" }}>
                        {content}
                      </p>
                    </div>
                  );
                }
              } else if (content.trim().startsWith("ST*-")) {
                return (
                  <div key={content}>
                    <p
                      className="fw-bold text-light"
                      style={{ fontSize: "17px" }}>
                      {content.trim().substring(4)}
                    </p>
                  </div>
                );
              } else if (content.trim().startsWith("IU*-")) {
                return (
                  <div key={content}>
                    <img
                      src={content.substring(4)}
                      alt="blog-img"
                      width="800"
                      className="img-fluid mb-4 mt-4"
                    />
                  </div>
                );
              } else {
                return (
                  <div key={content}>
                    <p>{content.trim()}</p>
                  </div>
                );
              }
            })}
          </div>
          <h4
            className={
              selectedBlogPost.reference_links.length >= 1
                ? "d-block mt-4"
                : "d-none mt-4"
            }>
            Reference Links :
          </h4>
          <ul>
            {selectedBlogPost.reference_links.map((referenceLink) => {
              return (
                <li className="ms-lg-5 ms-3 mt-2" key={referenceLink}>
                  <a
                    href={referenceLink.url}
                    target="_blank"
                    rel="noreferrer"
                    className="link-light"
                    style={{
                      textDecoration: "underline",
                      textUnderlineOffset: "5px",
                    }}>
                    {referenceLink.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <br />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
