import React, { useRef } from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import emailjs from "@emailjs/browser";
import { public_key, service_id, template_id } from "../config";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(service_id, template_id, form.current, public_key).then(
      (result) => {
        alert("Thank you for your message. We will get back to you soon.");
      },
      (error) => {
        alert("Error in sending message. Please try later.");
      }
    );
  };

  return (
    <div className="container-fluid bg-dark px-0 mx-0">
      <Navbar />
      <div className="row">
        <div className="col-md-6 mt-5 mb-4 pe-0 me-0 ms-lg-5">
          <form ref={form} onSubmit={sendEmail} autoComplete="off">
            <h2 className="text-light ms-4 ms-lg-5 ">Contact Us</h2>
            <input
              className="ms-4 ms-lg-5 mt-lg-4 mt-3 contact-input-fields"
              placeholder="Email Address"
              name="email_address"
            />
            <br />
            <input
              className="ms-4 ms-lg-5 mt-lg-4 mt-3 contact-input-fields"
              placeholder="Full Name"
              name="user_name"
            />
            <br />
            <textarea
              className="ms-4 ms-lg-5 mt-lg-4 mt-3 contact-input-fields"
              placeholder="Ask a question or just say Hi"
              rows="7"
              name="message"
            />
            <br />
            <button
              type="submit"
              value="Send"
              className="ms-4 ms-lg-5 mt-lg-4 mt-3 btn btn-outline-light px-4 py-2">
              Send
              <i className="fas fa-paper-plane ms-2" />
            </button>
          </form>
        </div>
        <div className="col-md-4 mt-5 mb-4 text-light">
          <h4 className="ms-4 ms-lg-5 mt-lg-5 pt-lg-5">
            We'd love to hear from you.
          </h4>
          <p className="ms-4 ms-lg-5 mt-4 pe-3">
            We'll answer every question you might have. Looking forward to hearing
            from you.
          </p>
          <h6 className="ms-4 ms-lg-5 mt-4 mt-lg-5 pe-3">
            <i className="fas fa-envelope me-3" />
            club.perspectiev@gmail.com
          </h6>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
