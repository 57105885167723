import React from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import AboutImage from "../media/about.png";

const About = () => {
  return (
    <div className="container-fluid bg-dark px-0 mx-0">
      <Navbar />
      <div className="container mt-5">
        <h2 className="text-light ps-4 ps-lg-5">About Us</h2>
        <img
          src={AboutImage}
          className="ps-4 ps-lg-5 mt-lg-4 mt-3 about-image pb-lg-3"
        />
        <h3 className="text-light ps-4 ps-lg-5 mt-5">Perspectiev.club</h3>
        <p
          className="text-light ps-4 ps-lg-5 mt-3 pe-3 pe-lg-5 pb-lg-3"
          style={{ fontSize: "17.5px" }}>
          Perspectiev is a knowledge sharing project to share insights and
          perspectiev.clubs on mobility futures. Perspectiev explores a wide
          array of discussions ranging from electric vehicles, transport
          policies, advanced transport technologies and effects of the current
          mobility ecosystems on the way we perceive transport. The goal for
          persectiev club is to start a dialogue in the interest of mobility
          futures and seek perspectiev.clubs that can efficiently benefit the
          way we travel.
        </p>
        <h3 className="text-light ps-4 ps-lg-5 mt-5">Our Story</h3>
        <p
          className="text-light ps-4 ps-lg-5 mt-3 pe-3 pe-lg-5"
          style={{ fontSize: "17.5px" }}>
          The word is rapidly evolving and the mobility landscapes are
          undergoing technological, policy and operational paradigm shifts.
          These new shifts occur through electric vehicles, high-end battery
          technologies, innovative risk-sharing and ownership models for
          businesses and many innovative interventions across the globe.
        </p>
        <p
          className="text-light ps-4 ps-lg-5 pt-3 pe-3 pe-lg-5"
          style={{ fontSize: "17.5px" }}>
          The awareness about the trajectory of mobility future very clustered
          and challenging to understand. Confidently envisaging a future for
          mobility is difficult because of the ambiguity and lack of clarity as
          to which technology will pick up pace. Possibility of a future with
          mixed technologies and multiple applications can change behaviours of
          the mobility stakeholders.
        </p>
        <p
          className="text-light ps-4 ps-lg-5 pt-3 pe-3 pe-lg-5"
          style={{ fontSize: "17.5px" }}>
          Perspectiev.club is an initiative that is aimed at brining
          cross-cutting narratives that influence the future of mobility, define
          how we travel and how we will travel. It is a platform for exploring
          niche areas, existent gaps and innovative solutions to global mobility
          futures.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default About;
