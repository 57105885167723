import Axios from "axios";
import { api_url } from "../config";

const getAllBlogPosts = async () => {
  var blogPosts = await Axios.get(api_url + "/blogposts")
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.status === 404) {
        console.log("No Blog Posts Found");
      }
    });
  return blogPosts;
};

export { getAllBlogPosts };
