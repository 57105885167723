import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { getAllBlogPosts } from "../functions/BlogPostFunctions";
import { sahil_url } from "../config";
import Loading from "./Loading";

const Blogs = () => {
  const [isLoading, setIsLoading] = useState("true");
  const [blogPosts, setBlogPosts] = useState([
    {
      _id: "",
      title: "",
      image_url: "",
      date: "",
      author: "",
      tags: [],
      reading_time: "",
      content: "",
      post_of_the_month: false,
      reference_links: [
        {
          name: "",
          url: "",
        },
      ],
    },
  ]);
  useEffect(() => {
    getAllBlogPosts().then((blPsts) => {
      setBlogPosts(blPsts);
      setIsLoading(false);
    });
  }, []);

  const redirectToBlogPage = (e, blogPostOfTheMonthTitle) => {
    e.preventDefault();
    let blogPostTitle = blogPostOfTheMonthTitle.replaceAll(" ", "_");
    window.location.href = "/article/" + blogPostTitle;
  };

  const [searchTerm, setSearchTerm] = useState("");

  return isLoading ? (
    <Loading />
  ) : (
    <div className="container-fluid bg-dark px-0 mx-0 text-light">
      <Navbar />
      <div className="row text-light container ms-lg-5 ps-lg-5 ms-3 ps-0 mb-lg-3">
        <h3 className="mt-5">All Articles ({blogPosts.length})</h3>
        <input
          className="ms-2 search-bar py-2 text-light mb-lg-0 mb-4 mt-lg-4 mt-3"
          placeholder="Search ....."
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />
        <div className="row">
          {blogPosts &&
            blogPosts
           .sort((a, b) => b.article_number - a.article_number)
              .filter((bp) => {
                if (
                  bp.date.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  bp.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  bp.author.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  bp.reading_time
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                  bp.tags.includes(searchTerm.toUpperCase())
                ) {
                  return bp;
                }
              })
              .map((blogPost) => {
                return (
                  <div
                    className="col-lg-4 col-12 mb-5 mb-lg-3 mt-lg-5 mt-2"
                    key={blogPost._id}>
                    <img
                      src={blogPost.image_url}
                      alt="blog-post"
                      width="350"
                      className="img-fluid mb-3"
                    />
                    <br />
                    <p
                      className="link-light pe-lg-4 pe-3"
                      onClick={(e) => redirectToBlogPage(e, blogPost.title)}
                      style={{
                        textDecoration: "none",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}>
                      {blogPost.title}
                    </p>
                    <p className="mt-2 mb-2">
                      <img
                        src={sahil_url}
                        alt="author"
                        width="35"
                        className="me-2 rounded"
                      />
                      {blogPost.author}
                    </p>
                    <p className="mb-2" style={{ fontSize: "14px" }}>
                      <i className="fas fa-calendar-days me-2" />
                      {blogPost.date} &nbsp;• &nbsp;
                      <i className="fas fa-clock me-2" />
                      {blogPost.reading_time}
                    </p>
                    <div>
                      <i className="fas fa-tags me-2" />
                      {blogPost.tags.map((tag) => {
                        return (
                          <p
                            key={tag}
                            className="text-dark d-inline bg-light me-2 px-2 py-1 rounded fw-bold"
                            style={{ fontSize: "12px" }}>
                            {tag}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
