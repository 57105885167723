import React, { useState, useEffect } from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { subscribeUserToBlog } from "../functions/SubscriberFunctions";
import { getAllBlogPosts } from "../functions/BlogPostFunctions";
import { sahil_url } from "../config";
import Loading from "./Loading";

const Home = () => {
  const [isLoading, setIsLoading] = useState("true");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const subscribeUser = (e) => {
    e.preventDefault();
    subscribeUserToBlog(name.trim(), email.trim());
  };

  const [blogPostOfTheMonth, setBlogPostOfTheMonth] = useState({
    _id: "",
    title: "",
    image_url: "",
    date: "",
    author: "",
    tags: [],
    reading_time: "",
    content: "",
    post_of_the_month: false,
    reference_links: [
      {
        name: "",
        url: "",
      },
    ],
  });
  useEffect(() => {
    getAllBlogPosts().then((blPsts) => {
      for (let blogPost of blPsts) {
        if (blogPost.post_of_the_month) {
          setBlogPostOfTheMonth(blogPost);
          break;
        }
      }
    });
  }, []);

  const [blogPosts, setBlogPosts] = useState([
    {
      _id: "",
      title: "",
      image_url: "",
      date: "",
      author: "",
      tags: [],
      reading_time: "",
      content: "",
      post_of_the_month: false,
      reference_links: [
        {
          name: "",
          url: "",
        },
      ],
    },
  ]);
  useEffect(() => {
    getAllBlogPosts().then((blPsts) => {
      const filteredBlogPosts = blPsts.filter(
        (bp) => bp.title !== blogPostOfTheMonth.title
      );
      setBlogPosts(filteredBlogPosts);
      setIsLoading(false);
    });
  }, [blogPostOfTheMonth]);

  const redirectToBlogPage = (e, blogPostOfTheMonthTitle) => {
    e.preventDefault();
    let blogPostTitle = blogPostOfTheMonthTitle.replaceAll(" ", "_");
    window.location.href = "/article/" + blogPostTitle;
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className="container-fluid bg-dark px-0 mx-0">
      <Navbar />
      <div className="row">
        <div className="col-md-5 mt-5 mb-lg-4 pe-0 me-0 ms-lg-5">
          <form onSubmit={subscribeUser} autoComplete="off">
            <h1 className="text-light ms-4 ms-lg-5">Welcome to the</h1>
            <h2 className="text-light ms-4 ms-lg-5">perspectiev.club</h2>
            <h5 className="text-light fw-light ms-4 ms-lg-5 mt-4 pe-lg-5 me-lg-5 me-3 pe-3 mb-lg-2 mb-3">
              Join perspectiev.club for exclusive insights on future mobility
              tech.
            </h5>
            <input
              className="ms-4 ms-lg-5 mt-lg-4 mt-3 home-input-fields"
              placeholder="Email Address *"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <br />
            <input
              className="ms-4 ms-lg-5 mt-lg-4 mt-3 home-input-fields mb-lg-2"
              placeholder="Full Name *"
              id="name"
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            <button
              type="submit"
              value="Send"
              className="ms-4 ms-lg-5 mt-4 btn btn-outline-light px-4 py-2">
              Subscribe
              <i className="fas fa-bell ms-2" />
            </button>
          </form>
        </div>
        <div className="col-md-6 mt-5 mb-lg-4 text-light text-center">
          {blogPostOfTheMonth ? (
            <div className="position-relative text-center px-lg-0 px-4 bg-dark">
              <img
                src={blogPostOfTheMonth.image_url}
                alt="post-of-the-month"
                width="500"
                className="img-fluid"
                style={{ opacity: 0.4 }}
              />
              <div className="caption">
                <input
                  disabled
                  className="text-dark fw-bold bg-white border-0 text-center px-1 py-2 mb-lg-1"
                  defaultValue="ARTICLE OF THE MONTH"
                />
                <h6 className="mt-4">
                  <i className="fas fa-calendar-days me-2" />
                  {blogPostOfTheMonth.date} &nbsp; • &nbsp; &nbsp;
                  <i className="fas fa-clock me-2" />
                  {blogPostOfTheMonth.reading_time}
                </h6>
                <a
                  href="/blog/:id"
                  className="link-light"
                  style={{ textDecoration: "none" }}
                  onClick={(e) =>
                    redirectToBlogPage(e, blogPostOfTheMonth.title)
                  }>
                  <h3 className="mt-lg-4 mt-2 mx-lg-5 px-lg-5 mx-3 px-3">
                    {blogPostOfTheMonth.title}
                  </h3>
                </a>
                <h6 className="mt-lg-4 mt-2 pb-lg-2">
                  <img
                    src={sahil_url}
                    alt="author"
                    width="35"
                    className="me-2 rounded-circle"
                  />
                  {blogPostOfTheMonth.author}
                </h6>
                <div className="mt-lg-4 mt-3">
                  <i className="fas fa-tags me-2" />
                  {blogPostOfTheMonth.tags.map((tag) => {
                    return (
                      <p
                        key={tag}
                        className="text-dark d-inline bg-light me-2 px-2 py-1 rounded fw-bold"
                        style={{ fontSize: "12px" }}>
                        {tag}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className={blogPosts.length >= 1 ? "d-block" : "d-none"}>
        <div className="row mt-5 text-light container ms-lg-5 ps-lg-5 ms-3 ps-0 mb-lg-3 ">
          <h2 className="mb-4">Recent Articles</h2>
          {blogPosts.length >= 1 &&
            blogPosts
           .sort((a, b) => b.article_number - a.article_number)
            .slice(0, 3).map((blogPost) => {
              return (
                <div
                  className="col-lg-4 col-12 mb-5 mb-lg-3"
                  key={blogPost._id}>
                  <img
                    src={blogPost.image_url}
                    alt="blog-post"
                    width="325"
                    className="img-fluid mb-3"
                  />
                  <br />
                  <p
                    className="link-light pe-lg-4 pe-3"
                    onClick={(e) => redirectToBlogPage(e, blogPost.title)}
                    style={{
                      textDecoration: "none",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}>
                    {blogPost.title}
                  </p>
                  <p className="mt-2 mb-2">
                    <img
                      src={sahil_url}
                      alt="author"
                      width="35"
                      className="me-2 rounded"
                    />
                    {blogPost.author}
                  </p>
                  <p className="mb-2" style={{ fontSize: "14px" }}>
                    <i className="fas fa-calendar-days me-2" />
                    {blogPost.date} &nbsp;• &nbsp;
                    <i className="fas fa-clock me-2" />
                    {blogPost.reading_time}
                  </p>
                  <div>
                    <i className="fas fa-tags me-2" />
                    {blogPost.tags.map((tag) => {
                      return (
                        <p
                          key={tag}
                          className="text-dark d-inline bg-light me-2 px-2 py-1 rounded fw-bold"
                          style={{ fontSize: "12px" }}>
                          {tag}
                        </p>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
        <a style={{ textDecoration: "none" }} href="/articles">
          <button className="btn btn-outline-light px-5 py-2 mt-lg-5 d-block mx-auto">
            Read More Articles
          </button>
        </a>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
