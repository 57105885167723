import React from "react";
import NavBarLogo from "../media/logo.png";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark py-2 sticky-top">
      <div className="container-fluid">
        <a className="navbar-brand px-lg-5" href="/">
          <img src={NavBarLogo} width="200" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse d-flex-md justify-content-end px-lg-5 pt-2 pb-3"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <a
              href="/home"
              className="nav-link text-light pb-0 px-2 px-lg-3 menu-items"
            >
              Home
            </a>
            <a
              href="/articles"
              className="nav-link text-light pb-0 px-2 px-lg-3 menu-items"
            >
              Articles
            </a>
            <a
              href="/about"
              className="nav-link text-light pb-0 px-2 px-lg-3 menu-items"
            >
              About
            </a>
            <a
              href="/contact"
              className="nav-link text-light pb-0 px-2 px-lg-3 menu-items"
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
