import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="pt-5 pb-4 text-center text-light">
      <a
        href="https://www.instagram.com/perspectiev.club/"
        target="_blank"
        rel="noreferrer"
        className="link-light">
        <FontAwesomeIcon icon={faInstagram} style={{ fontSize: "22px" }} />
      </a>
      <a
        href="https://twitter.com/SahilBhandareal?t=QO3TDspV-nrUK3aabJX19w&s=09"
        target="_blank"
        rel="noreferrer"
        className="link-light">
        <FontAwesomeIcon
          icon={faTwitter}
          className="px-4"
          style={{ fontSize: "22px" }}
        />
      </a>
      <a
        href="https://www.linkedin.com/in/sahil-bhandare/"
        target="_blank"
        rel="noreferrer"
        className="link-light">
        <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: "22px" }} />
      </a>
      <footer className="mt-4">
        &copy; PERSPECTIEV.CLUB {new Date().getFullYear()}. All Rights Reserved.
        <p className="mb-0 pb-0 mt-3" style={{ fontSize: "13px" }}>
          Developed with <span style={{ color: "#00bdfe" }}>❤</span> by Anush
          Raghavender N
        </p>
      </footer>
    </div>
  );
};

export default Footer;
