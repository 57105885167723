import Axios from "axios";
import { api_url } from "../config";

const subscribeUserToBlog = async (name, email) => {
  if (!name || !email || name === " " || email === " ") {
    alert("Please fill all mandatory fields");
    return;
  }
  await Axios.post(api_url + "/subscriber", {
    name: name,
    email: email,
  })
    .then((response) => {
      if (response.status === 201) {
        alert("Subscribed Successfully");
      }
      return true;
    })
    .catch((error) => {
      if ((error.message = "Subscriber email already exists")) {
        alert("Subscriber email already exists.");
        return;
      }
      alert("Could not subscribe. Please try later.");
    });
};

export { subscribeUserToBlog };
